(function () {
  const validAgencyType = {
    'data': {
      'agencyTypes': [
        {
          'id': 380,
          'agencyTypeCode': 'CTM',
          'agencyTypeName': 'Corporate Travel Management',
          'isActive': true
        },
        {
          'id': 381,
          'agencyTypeCode': 'CON',
          'agencyTypeName': 'Consolidator',
          'isActive': false
        },
        {
          'id': 382,
          'agencyTypeCode': 'OTA',
          'agencyTypeName': 'Online Travel Agent(OTA)',
          'isActive': false
        },
        {
          'id': 383,
          'agencyTypeCode': 'RLA',
          'agencyTypeName': 'Retail Leisure Agency',
          'isActive': true
        },
        {
          'id': 384,
          'agencyTypeCode': 'WTO',
          'agencyTypeName': 'Wholesale/Tour Operator',
          'isActive': false
        },
        {
          'id': 385,
          'agencyTypeCode': 'OTH',
          'agencyTypeName': 'Other',
          'isActive': false
        }
      ]
    },
    'status': 200,
    'tawServiceErrorVM': null
  };

  function getAgencyType() {
    return validAgencyType;
  }

  window.agencyTypeDataService = {
    getAgencyType,
  };
})(window);
